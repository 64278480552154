<template>
	<div id="app">
		<router-view />
		<b-modal v-model="loading.status" no-close-on-esc no-close-on-backdrop hide-header hide-footer>載入中</b-modal>
		<b-modal v-model="error.status" ok-title="確認" ok-only no-close-on-esc no-close-on-backdrop hide-header @ok="closeErrorModal">{{ error.message }}</b-modal>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	async created() {
		try {
			await this.$store.dispatch('setLoading', { status: true })
			await this.$store.dispatch('bindShops')
			await this.$store.dispatch('bindDevices')
			await this.$store.dispatch('bindCatalog')
			await this.$store.dispatch('bindInventory')
			await this.$store.dispatch('bindAdjustments')
			await this.$store.dispatch('setLoading', { status: false })
		} catch (e) {
			console.error(e)
			await this.$store.dispatch('setError', { message: e.message, status: true })
			await this.$store.dispatch('setLoading', { status: false })
		}
	},

	computed: {
		...mapGetters(['loading', 'error']),
	},

	methods: {
		async closeErrorModal() {
			await this.$store.dispatch('setError', { message: null, status: false })
		},
	},
}
</script>
