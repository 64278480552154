import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '',
		component: () => import('@/layouts/BaseLayout'),
		props: {
			paths: [
				{ value: '/shops', text: '分店' },
				{ value: '/devices', text: '裝置' },
				{ value: '/menus', text: '菜單' },
				{ value: '/categories', text: '分類' },
				{ value: '/products', text: '商品' },
				{ value: '/adjustments', text: '盤點訂單' },
			],
		},
		children: [
			{ path: '/shops', component: () => import('@/views/ShopList') },
			{ path: '/shops/:id', component: () => import('@/views/ShopDetail') },
			{ path: '/devices', component: () => import('@/views/DeviceList') },
			{ path: '/devices/:id', component: () => import('@/views/DeviceDetail') },
			{ path: '/menus', component: () => import('@/views/MenuList') },
			{ path: '/menus/:id', component: () => import('@/views/MenuDetail') },
			{ path: '/categories', component: () => import('@/views/CategoryList') },
			{ path: '/categories/:id', component: () => import('@/views/CategoryDetail') },
			{ path: '/products', component: () => import('@/views/ProductList') },
			{ path: '/products/:id', component: () => import('@/views/ProductDetail') },
			{ path: '/adjustments', component: () => import('@/views/AdjustmentList') },
			{ path: '/adjustments/:id', component: () => import('@/views/AdjustmentDetail') },
		],
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
})

export default router
