import { firestoreAction } from 'vuexfire'
import { db, functions } from '@/firebase'
import { Adjustment, AdjustmentShop, AdjustmentDevice } from '@/models/adjustment'

export const adjustment = {
	state: () => ({
		adjustments: [],
		currentAdjustment: null,
	}),

	getters: {
		adjustments: state => {
			return state.adjustments
		},
		adjustment: state => id => {
			return state.adjustments.find(adjustment => adjustment.id === id)
		},
		currentAdjustment: state => {
			return state.currentAdjustment
		},
	},

	actions: {
		bindAdjustments: firestoreAction(async ({ bindFirestoreRef }) => {
			let queryRef = db.collection('adjustments')
			return await bindFirestoreRef('adjustments', queryRef)
		}),
		unbindAdjustments: firestoreAction(({ unbindFirestoreRef }) => {
			return unbindFirestoreRef('adjustments')
		}),
		createAdjustment: async (store, { adjustment }) => {
			const createAdjustment = functions.httpsCallable('createAdjustment')
			const result = await createAdjustment({ adjustment })
			return result.data
		},
		setAdjustmentProduct: async (store, { id, product }) => {
			const setAdjustmentProduct = functions.httpsCallable('setAdjustmentProduct')
			const result = await setAdjustmentProduct({ id, product })
			return result.data
		},
		removeAdjustmentProduct: async (store, { id, productId }) => {
			const removeAdjustmentProduct = functions.httpsCallable('removeAdjustmentProduct')
			const result = await removeAdjustmentProduct({ id, productId })
			return result.data
		},
		setAdjustmentStatus: async (store, { id, status }) => {
			const setAdjustmentStatus = functions.httpsCallable('setAdjustmentStatus')
			const result = await setAdjustmentStatus({ id, status })
			return result.data
		},
		bindCurrentAdjustment: firestoreAction(async ({ getters, dispatch, bindFirestoreRef }, { shopId, deviceId }) => {
			const adjustmentRef = db.collection('adjustments').where('shop.id', '==', shopId).where('device.id', '==', deviceId).limit(1)
			const adjustmentSnap = await adjustmentRef.get()
			if (adjustmentSnap.docs.length > 0) {
				let queryRef = db.collection('adjustments').doc(adjustmentSnap.docs[0].data().id)
				return await bindFirestoreRef('currentAdjustment', queryRef)
			} else {
				const adjustment = {
					...Adjustment(),
					shop: AdjustmentShop(getters.currentShop),
					device: AdjustmentDevice(getters.currentDevice),
				}
				const result = await dispatch('createAdjustment', { adjustment })
				let queryRef = db.collection('adjustments').doc(result.id)
				return await bindFirestoreRef('currentAdjustment', queryRef)
			}
		}),
		unbindCurrentAdjustment: firestoreAction(({ unbindFirestoreRef }) => {
			return unbindFirestoreRef('currentAdjustment')
		}),
	},
}
