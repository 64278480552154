import { firestoreAction } from 'vuexfire'
import { db, functions } from '@/firebase'

export const inventory = {
	state: () => ({
		inventory: [],
	}),

	getters: {
		inventory: state => {
			return state.inventory
		},
		inventoryItem: state => id => {
			return state.inventory.find(inventoryItem => inventoryItem.id === id)
		},
	},

	actions: {
		bindInventory: firestoreAction(async ({ bindFirestoreRef }, { shopId = null } = {}) => {
			let queryRef = db.collection('inventory')
			if (shopId) queryRef.where('shop.id', '==', shopId)
			return await bindFirestoreRef('inventory', queryRef)
		}),
		unbindInventory: firestoreAction(({ unbindFirestoreRef }) => {
			return unbindFirestoreRef('inventory')
		}),
		createInventoryItem: async (store, { inventoryItem }) => {
			const createInventoryItem = functions.httpsCallable('createInventoryItem')
			const result = await createInventoryItem({ inventoryItem })
			return result.data
		},
		updateInventoryItem: async (store, { inventoryItem }) => {
			const updateInventoryItem = functions.httpsCallable('updateInventoryItem')
			const result = await updateInventoryItem({ inventoryItem })
			return result.data
		},
	},
}
